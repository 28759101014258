<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <div>
      <b-card>
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <div class="mt-1">
            <h2>Payment</h2>
            <h6 class="mt-1">Choose Payment Method</h6>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="my-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container w-50"
            variant="outline-primary"
            @click="payCash()"
          >
            <img
              :src="require('@/assets/images/logo/cash.png')"
              class="img-fluid w-25 mt-2"
            />
            <h3 class="my-2">Logpoint</h3>
          </b-button>
          <b-button
            class="my-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container w-50 mx-5"
            variant="outline-primary"
            @click="payCard()"
          >
            <img
              :src="require('@/assets/images/logo/visa.png')"
              class="img-fluid w-50 mt-2"
            />
            <h3 class="my-2">Debit / Credit Card</h3>
          </b-button>
          <b-button
            class="my-5 rounded box-shadow-1 d-flex flex-column justify-content-between align-items-center package-container w-50"
            variant="outline-primary"
            @click="payEwallet()"
          >
            <img
              :src="require('@/assets/images/logo/online-transfer.png')"
              class="img-fluid w-25 mt-2"
            />
            <h3 class="my-2">Online Transfer / E-Wallet</h3>
          </b-button>
        </div>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <b-button
          @click="onBack"
          variant="light"
          :disabled="isDisabled"
          v-b-modal.modal-center
          class="box-shadow-1 px-5 mr-3 w-25"
          >Back</b-button
        >
        <b-button
          @click="onSubmit"
          :disabled="isDisabled"
          variant="gradient-primary"
          v-b-modal.modal-center
          class="box-shadow-1 px-5 w-25"
          >Create Order & Pay</b-button
        >
      </div>
    </div>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Processing... Please wait.</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { getUserData } from "@/auth/utils";
export default {
  data() {
    return {
      isSubmitting: false,
      form: {},
      isDisabled: false,
      userData: {},
    };
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
    }
  },
  methods: {
    onSubmit() {
      this.$http.post(`order/create`, this.form).then((response) => {
        if (response.data.status) {
          this.$router.push({
            name: "DHL-delivery-success",
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "XIcon",
              variant: "warning",
            },
          });
        }
        this.isSubmitting = false;
      });

      // this.isSubmitting = true;
      // this.isDisabled = true;
      // if (this.form.payment_method != "") {
      //   this.$http.post(`store`, this.form).then((response) => {
      //     if (response.data.status) {
      //       this.form.order_id = response.data.order.payment_id;
      //       this.form.grand_total = response.data.order.grand_total;
      //       var baseURL = window.location.origin;
      //       var newPath = `/dhl-order/ordersummary?payment_id=${this.form.order_id}`;
      //       this.userData = getUserData();
      //       this.$http
      //         .get(
      //           `pay_order?amount=${this.form.grand_total}&order_id=${this.form.order_id}&return_url=${baseURL}${newPath}&name=${this.userData.name}&phone=${this.userData.phone}&email=${this.userData.email}&detail=MiniDC Order Payment`
      //         )
      //         .then((response) => {
      //           if (response.data.status) {
      //             if (this.form.payment_method == "LOGPOINT") {
      //               this.$router.push({
      //                 name: "dhl-order-ordersummary",
      //                 params: { data: this.form.order_id },
      //               });
      //             } else {
      //               window.open(response.data.url);
      //             }
      //           } else {
      //             if (response.data.errors) {
      //               var errors = response.data.errors;
      //               for (var a in errors) {
      //                 var error = errors[a];
      //                 this.$toast({
      //                   component: ToastificationContent,
      //                   props: {
      //                     title: error,
      //                     icon: "XIcon",
      //                     variant: "warning",
      //                   },
      //                 });
      //               }
      //             } else {
      //               this.$toast({
      //                 component: ToastificationContent,
      //                 props: {
      //                   title: response.data.message,
      //                   icon: "XIcon",
      //                   variant: "warning",
      //                 },
      //               });
      //             }
      //             this.isSubmitting = false;
      //             this.isDisabled = false;
      //           }
      //         });
      //     } else {
      //       if (response.data.errors) {
      //         var errors = response.data.errors;
      //         for (var a in errors) {
      //           var error = errors[a];
      //           this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //               title: error,
      //               icon: "XIcon",
      //               variant: "warning",
      //             },
      //           });
      //         }
      //       } else if (typeof response.data.message == "array") {
      //         var errors = response.data.message;
      //         for (var a in errors) {
      //           var error = errors[a];
      //           this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //               title: error,
      //               icon: "XIcon",
      //               variant: "warning",
      //             },
      //           });
      //         }
      //       } else {
      //         this.$toast({
      //           component: ToastificationContent,
      //           props: {
      //             title: response.data.message,
      //             icon: "XIcon",
      //             variant: "warning",
      //           },
      //         });
      //       }
      //     }
      //   });
      // } else {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: "Please choose a payment method",
      //       icon: "XIcon",
      //       variant: "warning",
      //     },
      //   });
      //   this.isSubmitting = false;
      //   this.isDisabled = false;
      // }
    },
    payCash() {
      this.form.payment_method = "LOGPOINT";
    },
    payCard() {
      this.form.payment_method = "CREDIT CARD";
    },
    payEwallet() {
      this.form.payment_method = "E WALLET";
    },

    onBack() {
      this.$router.push({
        name: "DHL-delivery",
        params: { data: this.form },
      });
    },
  },
};
</script>

<style scoped>
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
</style>
